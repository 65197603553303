.xdsoft_autocomplete {
	/*	-moz-box-sizing: border-box !important;
	box-sizing: border-box !important;*/
  
	div, span {
	  /*	-moz-box-sizing: border-box !important;
	  box-sizing: border-box !important;*/
	}
  
	display: inline;
	position: relative;
	word-spacing: normal;
	text-transform: none;
	text-indent: 0px;
	text-shadow: none;
	text-align: start;
	width: 100% !important;
  
	.xdsoft_input {
	  position: relative;
	  z-index: 2;
	}
  
	.xdsoft_autocomplete_dropdown {
	  position: absolute;
	  border: 1px solid #efecfb;
	  border-top-color: #efecfb;
	  cursor: default;
	  display: none;
	  z-index: 1001;
	  margin-top: -1px;
	  margin-inline-end: 2px;
	  background-color: #fff;
	  min-width: 100%;
	  overflow: auto;
	  max-height: 400px !important;
	  border-radius: 0 0 3px 3px;
	  width: calc(100% - 1rem) !important;
	}
  
	.xdsoft_autocomplete_hint {
	  position: absolute;
	  z-index: 1;
	  color: #ccc !important;
	  -webkit-text-fill-color: #ccc !important;
	  text-fill-color: #ccc !important;
	  overflow: hidden !important;
	  white-space: pre !important;
	  height: 40px !important;
	  width: 100% !important;
  
	  span {
		color: transparent;
		opacity: 0.0;
	  }
	}
  
	.xdsoft_autocomplete_dropdown > {
	  .xdsoft_autocomplete_copyright {
		color: #ddd;
		font-size: 10px;
		text-decoration: none;
		inset-inline-end: 5px;
		position: absolute;
		margin-top: -15px;
		z-index: 1002;
	  }
  
	  div {
		background: #fff;
		white-space: nowrap;
		cursor: pointer;
		line-height: 2em;
		padding: 2px 0px 2px 0px;
		color: #605e7e;
		font-size: 15px;
	  }
	}
  }