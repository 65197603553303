

.live-rating {
	font-size: 43px;
	margin-inline-start: 10px;
	color: hotPink;
	top: -3px;
	position: relative;
}
pre.prettyprint {
	font-family: "Lucida Console", Monaco, monospace;
	display: block;
	margin: 20px 0;
	font-size: 13px;
	line-height: 20px;
	padding: 20px;
	background: #EEEEEE;
	border: 0;
}
/*----- Ratings -----*/
		.rating-stars {
			display: inline-block;
			cursor: pointer;
			color: #ebeefb;
			i{
				padding: 5px 8px;

			}
			&.sm {
				display: inline-block;
				font-size: 14px;
				color: #83829c;
				cursor: pointer;
				padding: 1px;
			}
			&.is--active,
			&.is--hover {
				color: #f1c40f;
			}
			&.is--no-hover,
			.fa-heart .is--no-hover {
				color: #3e4b5b;
			}
		
	input {
		display: none;
		margin: 0 auto;
		text-align: center;
		padding: .375rem .75rem;
		font-size: .9375rem;
		line-height: 1.6;
		color: #3d4e67;
		background-color: $white;
		background-clip: padding-box;
		border: 1px solid #d8dde6;
		border-radius: 3px;
		transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
	}
	&.star input {
		display: none;
	}
}
.rating-star .jq-star {
    width: 18px !important;
    height: 18px !important;
}
.jq-star-svg {
    padding-inline-start: 3px;
    width: 100%;
    height: 100%;
}
.jq-star {
    width: 100px;
    height: 100px;
    display: inline-block;
    cursor: pointer;
}

.rating-stars .rating-stars-container .rating-star.is--hover {
  color: #f1c40f !important;
}
.d-inline-flex  .rating-star.my-rating-5{
	margin-top: -3px;
	margin-inline-end: 10px;
}
.d-inline-flex  .rating-star.my-rating-5.star{
	margin-top: -3px;
	margin-inline-start: 10px;
}
.d-inline-flex{
 align-items: center;
}