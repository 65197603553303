.wickedpicker {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	background: #fefefe;
	margin: 0 auto;
	border-radius: .1px;
	width: 270px;
  
	/* height: 130px; */
	font-size: 14px;
	display: none;
	border-radius: 0 0 5px 5px;
	z-index: 99;
	margin-block-start: -3px;
	border: 1px solid #efecfb;
	box-shadow: 5px 4px 12px 4px rgb(79, 37, 225, 0.07);
	display:none !important;
  }
  
  .wickedpicker__title {
	background-image: -webkit-linear-gradient(top, #fff 0, #f2f2f2 100%);
	position: relative;
	background: #f2f2f2;
	margin: 0 auto;
	border-bottom: 1px solid #e5e5e5;
	padding: 12px 11px 10px 15px;
	color: #4C4C4C;
	font-size: inherit;
	display: none;
  }
  
  .wickedpicker__close {
	-webkit-transform: translateY(-25%);
	-moz-transform: translateY(-25%);
	-ms-transform: translateY(-25%);
	-o-transform: translateY(-25%);
	transform: translateY(-25%);
	position: absolute;
	top: 25%;
	inset-inline-end: 10px;
	color: #34495e;
	cursor: pointer;
  
	&:before {
	  content: "×";
	}
  }
  
  .wickedpicker__controls {
	padding: 10px 0;
	line-height: normal;
	margin: 0;
  }
  
  .wickedpicker__controls__control, .wickedpicker__controls__control--separator {
	vertical-align: middle;
	display: inline-block;
	font-size: inherit;
	margin: 0 auto;
	width: 35px;
  
	/* letter-spacing: 2.3px; */
	/* font-size: 17px; */
	/* padding: 10px 0; */
	/* height: 30px; */
  }
  
  .wickedpicker__controls__control-down {
	/* color:#34495e; */
	position: relative;
	display: block;
	margin: 3px auto;
	font-size: 18px;
	cursor: pointer;
  }
  
  .wickedpicker__controls__control-up {
	/* color:#34495e; */
	position: relative;
	display: block;
	margin: 3px auto;
	font-size: 18px;
	cursor: pointer;
  
	&:before {
	  content: '\f106';
	  font: normal normal normal 14px/1 FontAwesome;
	  font-size: inherit;
	  text-rendering: auto;
	}
  }
  
  .wickedpicker__controls__control-down:after {
	content: '\f107';
	font: normal normal normal 14px/1 FontAwesome;
	font-size: inherit;
	text-rendering: auto;
  }
  
  .wickedpicker__controls__control--separator {
	width: 5px;
  }
  
  .text-center, .wickedpicker__controls, .wickedpicker__controls__control, .wickedpicker__controls__control--separator, .wickedpicker__controls__control-down, .wickedpicker__controls__control-up, .wickedpicker__title {
	text-align: center;
  }
  
  .wickedpicker__controls__control-down:after, .wickedpicker__controls__control-up:before {
	font-style: normal;
	font-weight: 400;
	speak: none;
	display: inline-block;
	text-decoration: inherit;
	width: 20px;
	height: 20px;
	margin-inline-end: .2em;
	text-align: center;
	font-variant: normal;
	text-transform: none;
	line-height: 1em;
	margin-inline-start: .2em;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-size: 12px;
	border-radius: 50%;
	background: #efecfb;
	line-height: 20px;
	opacity: 0.6;
	color: #6b61ed;
  }
  
  .wickedpicker__controls__control--minutes, .wickedpicker__controls__control--hours, .wickedpicker__controls__control--meridiem {
	height: 30px;
	line-height: 2.3;
	font-size: 14px;
	font-weight: 500;
  }