
@import "variables";


/* ###### plugins ####### */
@import "plugins/autocomplete/jquery.autocomplete";
@import "plugins/chat/jquery.convform";
@import "plugins/color-picker/spectrum";
@import "plugins/fancyuploder/fancy_fileupload";
@import "plugins/fullcalendar/fullcalendar";
@import "plugins/jquery-countdown/countdown";
@import "plugins/jquery-uislider/jquery-ui";
@import "plugins/jquery.flexdatalist/jquery.flexdatalist";
@import "plugins/jqvmap/jqvmap";
@import "plugins/jvectormap/jquery-jvectormap-2.0.2";
@import "plugins/jvectormap/jqvmap";
@import "plugins/lightgallery/gallery";
@import "plugins/nice-select/nice-select";
@import "plugins/owl-carousel/owl.carousel";
@import "plugins/owl-carousel/owl.theme.default";
@import "plugins/perfect-scrollbar/perfect-scrollbar";
@import "plugins/prettycheckbox/pretty-checkbox.min";
@import "plugins/video/insideElementDemo";
@import "plugins/wildtime/wickedpicker.min";
@import "plugins/primo-slider/primo-slider";
@import "plugins/ratings-2/demo";
@import "plugins/smart-wizard/smart_wizard";
@import "plugins/smart-wizard/smart_wizard_theme_dots";